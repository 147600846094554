import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogPageTemplate = ({
  data: {
    markdownRemark: {
      frontmatter: { slug, title, description },
    },
    allMarkdownRemark,
  },
}) => {
  return (
    <Layout>
      <SEO title={title} description={description} pathname={slug} />
      <div className="relative bg-dark pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-2/3 sm:h-2/3" />
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h1 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
              {title}
            </h1>
            <p className="mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-500 sm:mt-4">
              {description}
            </p>
          </div>
          <div className="mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
            {allMarkdownRemark.nodes.map(
              ({
                frontmatter: {
                  slug,
                  title,
                  description,
                  featureImage,
                  featureImageAlt,
                },
              }) => {
                return (
                  <Link to={`/${slug}/`} className="block">
                    <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                      <div className="flex-shrink-0">
                        <Img
                          className="h-48 w-full object-cover"
                          fluid={featureImage.childImageSharp.fluid}
                          alt={featureImageAlt}
                        />
                      </div>
                      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                        <div className="flex-1">
                          {/* <p className="text-sm leading-5 font-medium text-indigo-600">
                          <a href={slug} className="hover:underline">
                            Blog
                          </a>
                        </p> */}
                          <h2 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                            {title}
                          </h2>
                          <p className="mt-3 text-base leading-6 text-gray-500">
                            {description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              }
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPageTemplate

export const pageQuery = graphql`
  query BlogPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "blog-page" } }) {
      frontmatter {
        slug
        title
        description
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          slug
          title
          date
          description
          featureImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          featureImageAlt
        }
      }
    }
  }
`
